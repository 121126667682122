exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-ashley-js": () => import("./../../../src/pages/about/ashley.js" /* webpackChunkName: "component---src-pages-about-ashley-js" */),
  "component---src-pages-about-coco-js": () => import("./../../../src/pages/about/coco.js" /* webpackChunkName: "component---src-pages-about-coco-js" */),
  "component---src-pages-about-dari-js": () => import("./../../../src/pages/about/dari.js" /* webpackChunkName: "component---src-pages-about-dari-js" */),
  "component---src-pages-about-evelyn-js": () => import("./../../../src/pages/about/evelyn.js" /* webpackChunkName: "component---src-pages-about-evelyn-js" */),
  "component---src-pages-about-fox-js": () => import("./../../../src/pages/about/fox.js" /* webpackChunkName: "component---src-pages-about-fox-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-sureena-js": () => import("./../../../src/pages/about/sureena.js" /* webpackChunkName: "component---src-pages-about-sureena-js" */),
  "component---src-pages-about-sydney-js": () => import("./../../../src/pages/about/sydney.js" /* webpackChunkName: "component---src-pages-about-sydney-js" */),
  "component---src-pages-about-taryn-js": () => import("./../../../src/pages/about/taryn.js" /* webpackChunkName: "component---src-pages-about-taryn-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

